@font-face {
	font-family: 'Calibri-Bold';
	src: url('/assets/bootstrap-3.3.5/fonts/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
	url('/assets/bootstrap-3.3.5/fonts/Calibri-Bold.woff') format('woff'),
	url('/assets/bootstrap-3.3.5/fonts/Calibri-Bold.ttf') format('truetype'),
	url('/assets/bootstrap-3.3.5/fonts/Calibri-Bold.svg#Calibri-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

}
@font-face {
  font-family: 'Calibri';
  src: url('/assets/bootstrap-3.3.5/fonts/Calibri.eot?#iefix') format('embedded-opentype'),
  url('/assets/bootstrap-3.3.5/fonts/Calibri.woff') format('woff'),
  url('/assets/bootstrap-3.3.5/fonts/Calibri.ttf')  format('truetype'),
  url('/assets/bootstrap-3.3.5/fonts/Calibri.svg#Calibri') format('svg');
  font-weight: normal;
  font-style: normal;
	font-display: swap;

}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing:    border-box;
	box-sizing:         border-box;
	-moz-hyphens:none;
	-webkit-hyphens:none;
	-o-hyphens:none;
	-ms-hyphens:none;
}
article, aside, details, figcaption, figure, footer, header, nav, section, summary {
	display: block;
}
.min-height{
  min-height: 800px
}

audio, canvas, video {
	display: inline-block;
}

.item-box1{
	margin: 30px 0 5px 0;
	float: left;
	width: 100%;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
[hidden] {
	display: none;
}
html {
	font-size: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
.contact-frm-inner {
	width: 100%;
	float: left;
	margin-bottom: 10px;
}
html, button, input, select, textarea {
	font-family: Calibri;
}
element.style {
	position: relative;
	min-height: 100%;
	top: 0px;
}
body {
	color: #141412;
	line-height: 1.5;
	margin: 0;
	font-family: Calibri;
	font-size: 14px;
	line-height: 1.42857143;
	color: rgb(51, 51, 51);
	background-color: rgb(255, 255, 255);
	top: 0px !important;
}
.pt-45{
	padding-top: 45px;
}
h1.about-entry-title {
    font-family: Calibri-Bold;
	color:#1a7587;
	margin-bottom: 20px;
}
a {
	color: #ca3c08;
	text-decoration: none !important;
	outline:none !important;
}
a:visited {
	color: #ac0404;
}
a:focus {
	outline: thin dotted;
}
a:active, a:hover {
	color: #ea9629;
	outline: 0;
}
a:hover {
	text-decoration: underline;
}
h1, h2, h3, h4, h5, h6 {
	clear: both;
	font-family:Raleway;
	line-height: 1.3;
}
h1 {
	font-size: 34px;
	margin: 33px 0;
}
h2 {
	font-size: 30px;
	margin: 25px 0;
}
h3 {
	font-family: Calibri-Bold;
	font-size: 22px;
	margin: 15px 0;
}
h4 {
	font-size: 20px;
	margin: 25px 0;
}
h5 {
	font-size: 18px;
	margin: 30px 0;
}
h6 {
	font-size: 16px;
	margin: 36px 0;
}
address {
	font-style: italic;
	margin: 0 0 24px;
}
abbr[title] {
	border-bottom: 1px dotted;
}
b, strong {
	font-family: calibri-bold;
}
.inner_link > strong {
	color: #006375;
	font-family: calibri-bold;
	font-size: 14px;
	text-transform: capitalize;
}
dfn {
	font-style: italic;
}
mark {
	background: #ff0;
	color: #000;
}
p {
	font-family: Calibri;
	font-size: 16px;
	margin: 0 0 15px;
	float:left;
	width:100%;
}
code, kbd, pre, samp {
	font-family: calibri, serif;
	font-size: 14px;
	-webkit-hyphens: none;
	-moz-hyphens:    none;
	-ms-hyphens:     none;
	hyphens:         none;
}
pre {
	background: #f5f5f5;
	color: #666;
	font-family: calibri;
	font-size: 14px;
	margin: 20px 0;
	overflow: auto;
	padding: 20px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}
blockquote, q {
	-webkit-hyphens: none;
	-moz-hyphens:    none;
	-ms-hyphens:     none;
	hyphens:         none;
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: "";
	content: none;
}
blockquote {
	font-size: 18px;
	font-style: italic;
	font-weight: 300;
	margin: 24px 40px;
}
blockquote blockquote {
	margin-right: 0;
}
blockquote cite, blockquote small {
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
}
blockquote em, blockquote i {
	font-style: normal;
	font-weight: 300;
}
blockquote strong, blockquote b {
	font-weight: 400;
}
small {
	font-size: smaller;
}
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
dl {
	margin: 0 20px;
}
dt {
	font-weight: bold;
}
dd {
	margin: 0 0 20px;
}
menu, ol, ul {
	margin: 16px 0;
	padding: 0 0 0 40px;
}
ul {
	list-style-type: square;
}
nav ul, nav ol {
	list-style: none;
	list-style-image: none;
}
li > ul, li > ol {
	margin: 0;
}
img {
	-ms-interpolation-mode: bicubic;
	border: 0;
	vertical-align: middle;
}
img {
	border: 0 none;
	max-width: 100%;
	vertical-align: middle;
}
svg:not(:root) {
	overflow: hidden;
}
figure {
	margin: 0;
}
form {
	margin: 0;
}
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}
legend {
	border: 0;
	padding: 0;
	white-space: normal;
}
button, input, select, textarea {
	font-size: 100%;
	margin: 0;
	max-width: 100%;
	vertical-align: baseline;
}
button, input {
	line-height: normal;
}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled], input[disabled] {
	cursor: default;
}
input[type="checkbox"], input[type="radio"] {
	padding: 0;
}
input[type="search"] {
	-webkit-appearance: textfield;
	padding-right: 2px; /* Don't cut off the webkit search cancel button */
	width: 270px;
}
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
button::-moz-focus-inner, input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto;
	vertical-align: top;
}
table {
	border-bottom: 1px solid #ededed;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 14px;
	line-height: 2;
	margin: 0 0 20px;
	width: 100%;
}
caption, th, td {
	font-weight: normal;
	text-align: left;
}
caption {
	font-size: 16px;
	margin: 20px 0;
}
th {
	font-weight: bold;
	text-transform: uppercase;
}
td {
	border-top: 1px solid #ededed;
	padding: 6px 10px 6px 0;
}
del {
	color: #333;
}
ins {
	background: #fff9c0;
	text-decoration: none;
}
hr {
	/*background: url(images/dotted-line.png) repeat center top;*/
	background-size: 4px 4px;
	border: 0;
	height: 1px;
	margin: 0 0 24px;
}
select:not(:-internal-list-box) {
	overflow: visible !important;
}
select {
	-webkit-writing-mode: horizontal-tb !important;
	text-rendering: auto;
	color: black;
	letter-spacing: normal;
	word-spacing: normal;
	text-transform: none;
	text-indent: 0px;
	text-shadow: none;
	display: inline-block;
	text-align: start;
	-webkit-appearance: menulist;
	box-sizing: border-box;
	align-items: center;
	white-space: pre;
	-webkit-rtl-ordering: logical;
	background-color: white;
	cursor: default;
	margin: 0em;
	font: 400 13.3333px Arial;
	border-radius: 0px;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(169, 169, 169);
	border-image: initial;
}
.gal_title {
	background-image: url('assets/img/bg/title-bg.png');
	background-size: 100% 100%;
	color: #fff;
	font-family: calibri;
	font-size: 19px !important;
	margin: 0;
	padding: 5px 0 !important;
	float: left;
	width: 100%;
	text-align: center;
}

.ngg-gallery-thumbnail {
	margin-bottom: 30px;
}

div#page {
	float: left;
	width: 100%;
}
@media (max-width: 1599px)
.site {
	border: 0;
}
.site {
	background-color: rgb(255, 255, 255);
	border-left: 1px solid rgb(242, 242, 242);
	border-right: 1px solid rgb(242, 242, 242);
	margin: 0 auto;
	width: 100%;
}
div {
	display: block;
}
.widget.topbar {
	background-image: url('assets/img/bg/green-bg.jpg');
	margin: 0;
	padding: 5px 0;
}
.widget {
	background-color: none;
	font-size: 14px;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	margin: 0 0 24px;
	padding: 20px;
	word-wrap: break-word;
}
.topbar span {
	color: rgb(255, 255, 255);
	font-size: 22px;
}
.widget ul, .widget ol {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.contact-link ul li:first-child {
	background-image: url('assets/img/img/phone-icon.png');
	background-position: left center;
	background-repeat: no-repeat;
}
.contact-link ul li {
	display: inline-block;
	margin: 0 0 0 10px;
	padding: 7px 0 5px 35px;
}
.widget li {
	padding: 5px 0;
}
ul li {
	list-style: none;
}
li {
	display: list-item;
	text-align: -webkit-match-parent;
}
.topbar ul li a {
	color: rgb(255, 255, 255);
	font-family: Calibri;
	font-size: 15px;
}
.widget a {
	color: rgb(188, 54, 10);
	text-decoration: none !important;
}
a:visited {
	color: rgb(172, 4, 4);
}
ul li a {
	text-decoration: none !important;
}
a {
	color: rgb(202, 60, 8);
	text-decoration: none !important;
	outline: none !important;
}
a {
	text-decoration: none;
}
a {
	background-color: rgba(0, 0, 0, 0);
}
a:-webkit-any-link {
	color: -webkit-link;
	cursor: pointer;
	text-decoration: underline;
}
.contact-link ul li:nth-child(2) {
	background-image: url('assets/img/img/skype_icon.png');
	background-position: left center;
	background-repeat: no-repeat;
}
.contact-link ul li:nth-child(3) {
	background-image: url('assets/img/img/email_icon.png');
	background-position: left center;
	background-repeat: no-repeat;
}
.site-header {
	clear: both;
	float: left;
	position: relative;
	width: 100%;
}
.widget-logo-panel {
	float: left;
	padding: 0;
	width: 100%;
	margin: 0;
}
.logo {
	float: left;
	margin: -30px 0 0;
	width: 100%;
}
.entry-content img, .entry-summary img, .comment-content img, .widget img, .wp-caption {
	max-width: 100%;
}
#flags {
	overflow: hidden;
}
#flags ul {
	margin: 0;
}
#flags li {
	float: left !important;
	margin-bottom: 0 !important;
	padding: 0 !important;
	border: none !important;
	list-style: none !important;
	content: none !important;
}
#flags.size18 a.united-states[title="English"] {
	/*background: url(../images/flags18.png) -118px -352px no-repeat;*/
}
#flags.size18 a {
	width: 18px !important;
	height: 14px !important;
}
.flag {
	padding: 1px 0;
}
div#google_language_translator {
	/* float: right; */
	width: 100%;
	text-align: right;
	margin-top: 11px;
}
#google_language_translator {
	color: rgba(0, 0, 0, 0);
	width: auto !important;
}
.goog-te-gadget {
	font-family: arial;
	font-size: 11px;
	color: rgb(102, 102, 102);
	white-space: nowrap;
	color: rgb(68, 68, 68);
	font-size: 11px;
	font-family: "Arial",sans,sans-serif;
}
.goog-te-gadget .goog-te-combo {
	margin: 4px 0;
}
select.goog-te-combo {
	color: rgb(0, 0, 0)!important;
}
select.goog-te-combo {
	width: 143px !important;
	background: rgb(255, 255, 255);
	box-shadow: none !important;
	-moz-box-shadow: none !important;
	-webkit-box-shadow: none !important;
	padding: 3px !important;
	height: auto !important;
	border: 1px solid rgb(211, 211, 211) !important;
	border-radius: 0px !important;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px !important;
	-o-border-radius: 0px !important;
}
.goog-te-combo, .goog-te-banner *, .goog-te-ftab *, .goog-te-menu *, .goog-te-menu2 *, .goog-te-balloon * {
	font-family: arial;
	font-size: 10pt;
}
.goog-te-combo {
	margin-left: 4px;
	margin-right: 4px;
	vertical-align: baseline;
	*vertical-align: middle;
}
.goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active {
	font-size: 12px;
	font-weight: bold;
	color: rgb(68, 68, 68);
	text-decoration: none;
	line-height: 0.8em;
}
.goog-te-gadget img {
	vertical-align: middle;
	border: none;
}
.pull-right.div-export > img {
	margin: 50px 0 0;
}
/*
.navbar {
overflow: hidden;
background-color: #333;
}
.navbar a {
float: left;
font-size: 16px;
color: white;
text-align: center;
padding: 14px 16px;
text-decoration: none;
}
.dropdown {
float: left;
overflow: hidden;
}
.dropdown .dropbtn {
font-size: 16px;
border: none;
outline: none;
color: white;
padding: 14px 16px;
background-color: inherit;
font-family: inherit;
margin: 0;
}
.navbar a:hover, .dropdown:hover .dropbtn {
background-color: red;
}
.dropdown-content {
display: none;
position: absolute;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
}
.dropdown-content a {
float: none;
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
text-align: left;
}
.dropdown-content a:hover {
background-color: #ddd;
}
.dropdown:hover .dropdown-content {
display: block;
}*/
.navbar {
	background-color: rgb(1, 102, 122);
	margin-bottom: 0!important;
	width: 100%;
	float: left;
	border-radius: 0 !important;
}
.main-navigation {
	float: left;
	width: 100%;
}
.menu-toggle {
	display: none;
}
.float-100{
	float: left;
	width: 100%;
}
/* sub menu css */

.nav-menu .sub-menu, .nav-menu .children {
	background-color: #04A04C;
	border-image: none;
	border-style: none solid solid;
	border-width: 0;
	height: 1px;
	left: 0px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	z-index: 99999;
}

.nav-menu li a:hover {
	background-color: #07A04E !important;
}
/* End */


button, input, textarea {
	border: 1px solid rgb(1, 102, 122);
	font-family: inherit;
	padding: 5px;
}
button, input {
	line-height: normal;
}
button, input, optgroup, select, textarea {
	margin: 0;
	font: inherit;
	color: inherit;
}
button, select {
	text-transform: none;
}
button {
	overflow: visible;
}
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	position: absolute !important;
	height: 1px;
	width: 1px;
}
.menu-header-menu-container {
	float: left;
	width: 100%;
}
ul#primary-menu {
	margin: 0 auto;
	float: none;
	min-height: 45px;
}
ul.nav-menu, div.nav-menu > ul {
	margin: 0;
	padding: 0 00px 0 0;
	text-align: center;
}
.nav-menu li {
	display: inline-block;
	position: relative;
}
.nav-menu .current_page_item > a, .nav-menu .current_page_ancestor > a, .nav-menu .current-menu-item > a, .nav-menu .current-menu-ancestor > a {
	color: rgb(238, 238, 238);
}
.nav-menu li a {
	color: rgb(255, 255, 255);
	display: block;
	font-size: 19px;
	line-height: 47px;
	padding: 0px 15px;
	text-decoration: none;
	text-align: left;
}
ul li a {
	text-decoration: none !important;
}
ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}
.div-head {
	background-image: url('assets/img/bg/blue-bg.jpg');
	color: rgb(255, 255, 255);
	float: left;
	font-family: Raleway;
	font-size: 27px;
	padding: 15px 0;
	text-align: center;
	width: 100%;
}
/*Content Of page CSS*/
.site-main {
	clear: both;
	float: left;
	position: relative;
	width: 100%;
}
.site-content {
	float: left;
	width: 100%;
	text-align: justify;
}
/*.home .entry-content {
}*/
.entry-content {
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	word-wrap: break-word;
}

.entry-header, .entry-content, .entry-summary, .entry-meta {
	float: left;
	margin: 0 auto;
	width: 100%;
}
.entry-header {
	margin-bottom: 20px;
}
.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #ececec!important;
}
.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f9f9f9;
}
.entry-content {
	font-size: 16px;
	text-align: justify;
}
.clear:before, .clear:after, .attachment .entry-header:before, .attachment .entry-header:after, .site-footer .widget-area:before, .site-footer .widget-area:after, .entry-content:before, .entry-content:after, .page-content:before, .page-content:after, .navigation:before, .navigation:after, .nav-links:before, .nav-links:after, .gallery:before, .gallery:after, .comment-form-author:before, .comment-form-author:after, .comment-form-email:before, .comment-form-email:after, .comment-form-url:before, .comment-form-url:after, .comment-body:before, .comment-body:after {
	content: "";
	display: table;
}
p {
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
.slider-title {
	background-image: url('assets/img/bg/green-bg.jpg');
	color: rgb(255, 255, 255);
	font-family: calibri;
	font-size: 25px;
	margin: 0;
	padding: 3px 0;
	text-align: center;
}

.bx-wrapper {
	position: relative;
	margin: 0 auto 40px;
	padding: 0;
	*zoom: 1;
}
#advps_container3 .bx-wrapper .bx-viewport {
	border: 0px solid rgb(221, 221, 221);
	background: rgb(255, 255, 255);
}
.advps-slide img {
	border: 1px solid rgb(238, 238, 238);
}
.entry-content img, .entry-summary img, .comment-content img[height], img[class*="align"], img[class*="wp-image-"], img[class*="attachment-"] {
	height: auto;
}
.entry-content img, .entry-summary img, .comment-content img, .widget img, .wp-caption {
	max-width: 100%;
}
.advps-slide img {
	max-width: 245px !important;
}
.bx-wrapper img {
	max-width: 100%;
	display: block;
}
.advps-slide-field-three img {
	float: none;
	margin: 0px auto;
	clear: both;
	display: block;
}
.advps-excerpt-three {
	width: 100%;
	line-height: 20px;
}
#advps_container3 .advs-title, #advps_container3 .advs-title a {
	font-size: 16px;
	line-height: 18px;
}
#advps_container3 .advs-title, #advps_container3 .advs-title a {
	font-family: Calibri-Bold;
	text-align: center;
}
.advps-slide-container .advs-title {
	line-height: 30px;
}
.bx-controls.bx-has-pager {
	border-top: 1px solid rgb(221, 221, 221);
	float: left;
	width: 100% !important;
}
#advps_container3 .bx-wrapper .bx-pager {
	bottom: -35px;
	z-index: 999;
}
#advps_container3 .bx-wrapper .bx-pager {
	text-align: center;
}
.bx-wrapper .bx-pager {
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: rgb(102, 102, 102);
	padding-top: 20px;
}
.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
	position: absolute;
	/* bottom: -30px; */
	right: 0;
}
.bx-wrapper .bx-pager .bx-pager-item, .bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

/*Certificate CSS*/
.certificates {
	float: left;
	width: 100%;
}
.certi {
	float: left;
	width: 100%;
}
.content-title {
	background-image: url('assets/img/bg/green-bg.jpg');
	color: rgb(255, 255, 255);
	font-family: calibri;
	margin: 20px 0 0;
	padding: 5px 0;
	text-align: center;
}
.certificates ul {
	text-align: center;
	padding: 0;
}
ul {
	float: left;
}
menu, ol, ul {
	margin: 16px 0;
	padding: 0 0 0 40px;
}
ol, ul {
	margin-top: 0;
	margin-bottom: 10px;
}
ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}
.certificates ul li {
	display: inline-block;
	padding: 0 30px;
}
.entry-content ul li {
	font-family: calibri;
	font-size: 16px;
	text-align: justify;
}
ul li {
	list-style: none;
}
.div-sesame {
	float: left;
	margin: 0 0 30px;
	width: 100%;
	text-align: center;
}

.single-post-row h1.entry-title {
	background-image: url('./assets/img/img/title-bg.png');
	background-repeat: no-repeat;
	color: #fff;
	padding-left: 15px;
	font-size: 30px;
	float: left;
	width: 100%;
	background-size: 100% 100%;
	margin-bottom: 20px;
}
.nav-links a {
	background-color: #049a49;
	padding: 5px;
	font-size: 18px;
	font-family: calibri;
	color: #fff;
	font-style: normal;
	float: right;
}
.post-navigation a[rel="next"] {
	float: right;
	text-align: right;
}
.post-navigation a[rel="next"] {
	float: left!important;
	text-align: left!important;
}

.post-navigation {
	background-color: #fff;
	color: #ca3c08;
	float: left;
	font-size: 20px;
	font-style: italic;
	font-weight: 300;
	padding: 20px 0;
	width: 100%;
}

.single-post-row .col-lg-3 .cat_title {
	padding-left: 11px;
}
.cat_title {
	background-color: #01667a;
	color: #fff;
	float: left;
	font-family: calibri-bold;
	font-size: 30px;
	padding: 0 20px;
	width: 100%;
}
.lcp_catlist {
	padding-left: 14px;
	float: left;
	width: 100%;
	margin-top: 15px;
}
.b-right{
	border-right: 1px solid #d6d6d6;
}
.uses {
	padding: 0;
	float: left;
	width: 100%;
}
.uses li {
	float: left;
	width: 25%;
	margin-bottom: 10px;
}



.uses li img {

	border: 1px solid #d6d6d6;
}

.nav-links a:hover {
	background-color: #1A7587;
	color: #fff;
}

.lcp_catlist li a {
	color: #1a7587;
	line-height: 28px;
	font-size: 18px;
	background-image: url('./assets/img/img/arrow.png');
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 12px;
}

.lcp_catlist li{
	margin-bottom: 15px;
}

.single-post-row{
	margin-top: 20px;
}
.div-sesame > img {
	max-width: 100%;
}
.w-100{
	width: 100%;
}
/*FOOTER CSS*/
.site-footer {
	color: rgb(104, 103, 88);
	float: left;
	font-size: 14px;
	text-align: center;
	width: 100%;
}
.site-footer .sidebar-container {
	background: url('./assets/img/bg/blue-bg.jpg');
	padding: 20px 0;
	float: left;
	width: 100%;
}
.site-footer .widget-area {
	margin: 0 auto;
	max-width: 1170px;
	width: 100%;
}
.site-footer .widget-area, .sidebar .site-footer {
	text-align: left;
}
.site-footer .widget {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	color: rgb(255, 255, 255);
}
.site-footer .widget {
	padding: 0;
	margin: 0;
}
.site-footer .widget {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	color: rgb(255, 255, 255);
}
.site-footer .widget {
	padding: 0;
	margin: 0;
}
.address {
	float: left;
	width: 100%;
	height: 150px;
}
.address > p {
	background-image: url('assets/img/img/address.png');
	background-position: 0 5px;
	background-repeat: no-repeat;
	font-size: 16px;
	margin: 15px 0;
	padding: 0 0 0 30px;
}
.contact-no.foot-link {
	background-image: url('assets/img/img/call.png');
	background-position: 2px 5px;
	background-repeat: no-repeat;
	margin: 0 0 10px;
	padding: 0 0 0 30px;
	float: left;
	width: 100%;
}
.site-footer .widget a {
	color: rgb(255, 255, 255);
	font-size: 17px;
}
.site-footer a {
	color: rgb(104, 103, 88);
}
.widget a {
	color: rgb(188, 54, 10);
	text-decoration: none !important;
}
.fax-no.foot-link {
	background-image: url('assets/img/img/fax.png');
	background-position: 3px 3px;
	background-repeat: no-repeat;
	margin: 0 0 10px;
	padding: 0 0 0 30px;
	float: left;
	width: 100%;
}
.mail-id.foot-link {
	background-image: url('assets/img/img/mail.png');
	background-position: 4px 5px;
	background-repeat: no-repeat;
	padding: 0 0 0 30px;
	margin: 0 0 10px 0;
}
.skype-id.foot-link {
	background-image: url('assets/img/img/skype.png');
	background-position: 3px 3px;
	background-repeat: no-repeat;
	margin: 0 0 10px;
	padding: 0 0 0 30px;
}
.catproduct .catproductdiv{
	margin: 20px 0 0 0;
}

.site-info {
	background-image: url('assets/img/bg/black-bg.jpg');
	margin: 0 auto;
	padding: 15px 0;
	width: 100%;
	float: left;
}
.site-info p {
	color: rgb(255, 255, 255);
	font-size: 17px;
	margin: 0;
}
/*CSS*/
.our-products .content-title {
	margin-bottom: 30px;
}

.owl-carousel {
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper-outer {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.w-100{
	width: 100%;
}

.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
}

.owl-carousel .owl-wrapper {
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
}
.owl-carousel .owl-item {
	float: left;
}
.item-box {
	margin: 0 auto;
	max-width: 155px;
}
.img-box {
	box-shadow: 0 5px 5px #ddd;
	padding: 10px;
}

.home .item-title {
	-moz-hyphens: none;
	font-size: 17px;
	margin: 10px 0 0;
	text-align: center;
	color: rgb(0, 0, 0);
	font-family: calibri;
}

.item-title {
	-moz-hyphens: none;
	font-size: 14px;
	margin: 10px 0 0;
	text-align: center;
	color: rgb(95, 95, 95);
}
/*Home Page Css End*/



/*about Us page css Start*/

div#div_banner {
	float: left;
	width: 100%;
	text-align: center;
}
.entry-title {
	color: rgb(3, 100, 119);
	font-family: Calibri-bold;
	font-size: 34px;
	font-weight: normal;
	margin: 0 0 5px;
}

.about-entry-header, .about-entry-content, .about-entry-summary, .about-entry-meta {
	float: left;
	margin: 0 auto;
	width: 100%;
}
.about-entry-content {
	font-size: 16px;
	text-align: justify;
}

.setmargin {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}


.inr-pg p {
	width: 100%;
	float: left;
	margin-bottom: 6px;
	color: rgb(39, 39, 39);
	font-size: 18px;
}

.div-certi img {
	border: 2px solid #655E5E;
	margin-bottom: 20px;
	box-shadow: 7px 7px 7px #888888;
	margin-top: 20px;

}

.div-certi .col-lg-3 span {
	float: left;
	width: 100%;

	font-size: 16px;
	text-align: center;
}


.inr-pg h3.uses_title {
	float: left;
	width: 100%;
	border-bottom: 1px solid rgb(26, 117, 135);
	padding-bottom: 3px;
}
h3.uses_title {
	float: left;
	width: 100%;
	border-bottom: 1px solid rgb(26, 117, 135);
	padding-bottom: 4px;
}
.inr-pg .uses_title span {
	background-color: rgb(26, 117, 135);
	padding: 6px 32px;
	color: rgb(255, 255, 255);
	font-weight: normal!important;
	font-size: 18px;
	font-family: 'Raleway', sans-serif;
}
.uses_title span {
	background-color: rgb(26, 117, 135);
	padding: 6px 32px;
	color: rgb(255, 255, 255);
	font-weight: normal!important;
	font-size: 24px;
	font-family: 'Raleway', sans-serif;
}
.inr-pg ul {
	padding: 0;
	margin: 0;
}

.entry-content ul li {
	font-family: calibri;
	font-size: 16px;
	text-align: justify;
}
.inr-pg li {
	font-family: calibri;
	font-size: 16px !important;
	text-align: justify;
	color: rgb(39, 45, 45);
	line-height: 27px;
	font-size: 17px;
	background-image: url('assets/img/img/arrow.png');
	background-repeat: no-repeat;
	background-position: 0px 8px;
	padding-left: 20px;
}
.div-addr p a {
    color: #036a7d;
}

.image_box {
	float: left;
	margin: 0 0 30px;
	text-align: center;
	width: 100%;
}
.image_box > img {
	border: 1px solid rgb(221, 221, 221);
}
/*about Us page css End*/




/*Contact Us Start*/



.contact p {
	font-size: 19px;
}
.div-addr {
	float: left;
	width: 100%;
	margin-bottom: 30px;
}
.div-addr h3.uses_title {
	border-bottom: 1px solid rgb(26, 117, 135);
	float: left;
	padding-bottom: 0;
	width: 100%;
}
.div-addr .uses_title span {
	float: left;
	min-width: 160px;
	font-size: 18px;
}





.banner-height{
	min-height: 120px;
}

/*Contact Us End*/



/*responsive csss start*/

@media (max-width: 480px) and (min-width: 300px){
	.contact-link ul li {
		margin: 0!important;
		width: 100%;
	}
}
@media (max-width: 767px){

	.translator {
    	right: 7px !important;
    	top: 0 !important;
	}

	.contact-link ul li a {
		float: left;
		width: 100%;
		font-size: 13px;
	}
}
@media (max-width: 599px){
	.top-since, .contact-link {
		float: left;
		width: 100%;
	}
}
@media (max-width: 480px) and (min-width: 300px){
	.col-xs-fullwidth {
		float: left!important;
		width: 100%!important;
		text-align: center;
	}

	.logo {
		float: left;
		margin: 0;
		width: 100%;
	}
}
@media (max-width: 1069px){
	.div-export img {
		margin-top: 32px !important;
	}
}

@media (max-width: 767px){
	.pull-right.div-export {
		width: 100%;
		text-align: center;
		float: left;
	}
}


@media (max-width: 480px) and (min-width: 300px){
	.div-export img {
		margin-top: 18px !important;
	}
}


@media (max-width: 767px){
	.main-navigation {
		display: none;
	}
}

@media (max-width: 767px){

	.certificates ul li {
		padding: 0 7px;
	}
}

@media (max-width: 991px){

	.certificates ul li {
		padding: 0 10px;
	}
}


@media (max-width: 1199px){

	.certificates ul li {
		padding: 0 25px;
	}
}

@media (max-width: 667px){

	.certificates ul li {
		float: left;
		padding: 0 2px;
	}
}

@media (max-width: 768px){

	.certificates ul li {
		float: left;
		padding: 0 11px;
	}
}
@media (max-width: 1069px){
	.certificates ul li {
		float: left;
		padding: 0 7px;
	}
}




/*responsive csss start*/



/*Quick Contact Start*/


.quick-cont-main {
	float: left;
	position: fixed;
	top: 20%;
	z-index: 99999;
	right: 0;
}
.quick-image {
	float: left;
	position: absolute;
	left: -40px;
	cursor: pointer;
	top: 5px;
}

.quick-form {
	width: 250px;
	float: right;
	z-index: 9999;
	background: rgb(238, 238, 238);
	right: 0px;
	top: 212px;
	padding: 10px;
	border-radius: 6px;
	display: none;
	border: 3px solid rgb(0, 156, 72);
}
div.wpcf7 {
	margin: 0;
	padding: 0;
}
div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}
.quick-cont-main .quick-form .frm-right {
	width: 100%;
}

ul.nav-menu li:hover > ul{
	clip: inherit;
	overflow: inherit;
	height: inherit;
	width: inherit;
}

.nav-menu li ul li {
	border-top: 1px solid #30B36D;
	padding: 0;
	width: 100%;
	float: left;
	min-width: 190px;
}

.frm-right {
	width: 90%;
	float: left;
	margin-bottom: 10px;
}

.wpcf7-form-control-wrap {
	position: relative;
}

.quick-cont-main .quick-form .g-recaptcha {
	transform: scale(0.7);
	transform-origin: 0;
}
div.wpcf7 .wpcf7-recaptcha iframe {
	margin-bottom: 0;
}

.contact-frm input,.contact-frm select,.contact-frm textarea {
	width: 100%;
	max-height: 70px;
	padding: 5px;
	border: 1px solid #A7A7A7;
	resize: none;
}
.req-textbox {
border: 1px solid red !important;
}

.frm-right {
	width: 100%;
	float: left;
	margin-bottom: 10px;
}

.custom-btn {
	border-radius: 0!important;
	font-size: 1.75rem!important;
}
.text-white {
	color:#FFFFFF;
}

.frm-left {
	width: 100%;
	float: left;
	font-size: 19px;
	text-align: left;
}



/*Quick Contact End*/



/*Nav Bar Css Start*//*
.nav-contain{
width: 1193px;
}
.nav-a{
color: #FFF;
}
ul.nav-menu1, div.nav-menu1 > ul {
margin: 0;
padding: 0 00px 0 0;
text-align: center;
}
.nav-menu1 li {
display: inline-block;
position: relative;
}
.nav-menu1 li a {
color: rgb(255, 255, 255)!important;
display: block;
font-size: 19px;
line-height: 47px;
padding: 0px 15px;
text-decoration: none;
}
.nav-menu1 li a:hover {
background-color: #07A04E !important;
}

ul.nav-menu1, div.nav-menu1 > ul {
margin: 0;
padding: 0 0px 0 0;
text-align: center;
}
*/

.set-fonts{
border-bottom:1px solid #dddddd;
padding: 10px;
}
.light-font{
font-size: 15px;
font-weight: 900;
}


/*Nav Bar Css End*/
.bg-black {
	background: #000
}
#sidebarCollapse i {
	font-size: 1.5em;color: #FFF;
	z-index: 99;
	position: relative;
}
.mobile-nav {
	width: 100%;
    height: 100vh;
    background: #1A7587;
    color: #FFF;
	overflow-y: auto;
	padding: 35px 15px 35px 35px;
	position: fixed;
	top:0;
	z-index: 99

}
.mobile-nav .nav-menu li {
    display: flex;
    position: relative;
}
.mobile-nav .nav-menu li a {
	width: 100%;
	text-align: left;
}
.btn {
	border: none!important;
	width: auto!important;
	min-width: 100px;
}
.btn:focus,.btn:active {
	box-shadow: none!important;
	outline:none!important;
}
.bg-blue {
	background: #1a7587!important;
}
.bg-blue:hover {
	background: #1c8496!important;
}
.nav-menu1 {
	min-width:50px!important;
}

.goog-logo-link {
   display:none !important;
}

.goog-te-gadget{
   color: transparent !important;
}

.translator {
    position: absolute;
    right: 10%;
    top: 110px;
}

.entry-content a {
    color: #3d9e4a;
}
.banner-height .owl-dots {
    margin-top: -27px!important;
    position: relative;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #cebbbb !important;
}